<template>
  <div>
    <item-group-list-add-new
      ref="sidebar"
      :is-add-new-item-group-sidebar-active.sync="isAddingNew"
      @refetch-data="fetchItemGroups"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="isAddingNew = true"
              >
                <span class="text-nowrap">Add Item Group</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refItemGroupsTable"
        class="position-relative"
        :items="itemGroups"
        responsive
        :fields="tableColumns"
        primary-key="item_group_id"
        show-empty
        empty-text="No matching records found"
        :busy="loading"
      >
        <template v-slot:table-busy>
          <div class="d-flex justify-content-center my-4">
            <b-spinner
              type="grow"
              style="width: 3rem; height: 3rem;"
              variant="primary"
            />
          </div>
        </template>

        <template v-slot:cell(image)="data">
          <b-img-lazy
            v-if="data.item.item_group_image_url != null"
            :src="data.item.item_group_image_url"
            width="100"
            alt="item image"
          />
        </template>

        <template v-slot:cell(item_group_name)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="editItemGroup(data.item)"
          >
            {{ data.item.item_group_name }}
          </b-link>
        </template>

        <template v-slot:cell(price)="data">
          ${{ data.item.item_group_price }}
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            variant="primary"
            :to="{
              name: 'item-group',
              params: data.item,
            }"
            size="sm"
            class="mr-1"
          >
            Items
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            class="mr-0 mr-md-1"
            @click="refreshItemGroupFromQB(data.item.item_group_id)"
          >
            Reimport
          </b-button>
          <b-button
            variant="outline-danger"
            size="sm"
            @click="toggleConfirmDelete(data.item.item_group_id)"
          >
            <span class="d-none d-lg-block">
              Delete
            </span>
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <icon-modal
      ref="confirm"
      icon="XOctagonIcon"
      icon-classes="text-danger"
      @close="itemGroupId = null"
    >
      <p class="text-center">
        Are you sure you would like to delete this Item Group?
      </p>
      <template v-slot:footer>
        <b-button
          variant="default"
          @click="$refs.confirm.close()"
        >
          Go Back
        </b-button>
        <spinner-button
          variant="danger"
          :loading="deleting"
          @click="deleteItemGroup"
        >
          Delete
        </spinner-button>
      </template>
    </icon-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BSpinner,
  BImgLazy,
  BLink,
} from 'bootstrap-vue'
import store from '@/store'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import itemStoreService from '@/services/itemStoreService'
import vSelect from 'vue-select'
import IconModal from '@/layouts/components/IconModal.vue'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ItemGroupListAddNew from '@/views/ItemGroupListAddNew.vue'
import Vue from 'vue'

export default {
  setup() {
    // Store setup
    const ITEM_STORE_MODULE_NAME = 'app-item'
    if (!store.hasModule(ITEM_STORE_MODULE_NAME)) store.registerModule(ITEM_STORE_MODULE_NAME, itemStoreService)

    onUnmounted(() => {
      if (store.hasModule(ITEM_STORE_MODULE_NAME)) store.unregisterModule(ITEM_STORE_MODULE_NAME)
    })

    // Variable
    const toast = useToast()

    const refItemGroupsTable = ref(null)
    const confirm = ref(null)
    const sidebar = ref(null)

    const itemGroups = ref([])
    const loading = ref(false)
    const deleting = ref(false)
    const tableColumns = [
      {
        key: 'image',
        label: '',
      },
      {
        key: 'item_group_name',
        label: 'Name',
      },
      {
        key: 'item_group_display_name',
        label: 'Display Name',
      },
      {
        key: 'item_group_details',
        label: 'Description',
      },
      {
        key: 'price',
      },
      {
        key: 'actions',
      },
    ]
    const perPage = ref(25)
    const totalItems = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const isAddingNew = ref(false)
    const itemGroupId = ref(null)

    // Computed
    const dataMeta = computed(() => {
      const localItemsCount = refItemGroupsTable.value ? refItemGroupsTable.value.localItems.length : 0

      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalItems.value,
      }
    })

    // Functions
    const fetchItemGroups = () => {
      loading.value = true
      store.dispatch('app-item/fetchItemGroups', {
        page: currentPage.value,
        pageSize: perPage.value,
      })
        .then(response => {
          itemGroups.value = response.data.data
          totalItems.value = response.data.total
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loading.value = false
        })
    }

    const toggleConfirmDelete = id => {
      itemGroupId.value = id
      confirm.value.open()
    }

    const deleteItemGroup = () => {
      deleting.value = true
      store.dispatch('app-item/deleteItemGroup', {
        id: itemGroupId.value,
      }).then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully deleted the item group',
            icon: 'CheckSquareIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting item group. Please try again later',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        deleting.value = false
        fetchItemGroups()
        confirm.value.close()
      })
    }

    const editItemGroup = itemGroup => {
      sidebar.value.setItemGroup(itemGroup)
      isAddingNew.value = true
    }

    const refreshItemGroupFromQB = selectedItemGroupId => {
      store.dispatch('app-item/reimportItemGroup', {
        id: selectedItemGroupId,
      })
        .then(response => {
          const existingItemGroupIndex = itemGroups.value.findIndex(itemGroup => itemGroup.item_group_id === selectedItemGroupId)
          if (existingItemGroupIndex >= 0) {
            Vue.set(itemGroups.value, existingItemGroupIndex, response.data.item_group)
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error reimporting item group. Please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    // Watch
    watch([
      perPage,
      currentPage,
    ], () => {
      fetchItemGroups()
    })

    fetchItemGroups()

    return {
      refItemGroupsTable,
      confirm,

      itemGroups,
      loading,
      deleting,
      tableColumns,
      perPage,
      totalItems,
      currentPage,
      perPageOptions,
      dataMeta,
      isAddingNew,
      itemGroupId,

      sidebar,

      fetchItemGroups,
      toggleConfirmDelete,
      deleteItemGroup,
      editItemGroup,
      refreshItemGroupFromQB,
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    BImgLazy,
    BLink,
    vSelect,
    ItemGroupListAddNew,
    IconModal,
    SpinnerButton,
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
