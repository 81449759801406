<template>
  <b-sidebar
    id="add-new-item-group-sidebar"
    :visible="isAddNewItemGroupSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @shown="fetchItems"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-item-group-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          Add New Item Group
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Item Group Form -->
          <template v-if="itemGroupData.item_group_id > 0">

            <!-- Item Group Name -->
            <validation-provider
              #default="validationContext"
              name="Item Group Name"
              rules="required"
            >
              <b-form-group
                label="Item Group Name"
                label-for="item-group-name"
              >
                <b-form-input
                  id="item-group-name"
                  v-model="itemGroupData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Item Group Display Name -->
            <validation-provider
              #default="validationContext"
              name="Item Group Display Name"
              rules="required"
            >
              <b-form-group
                label="Item Group Display Name"
                label-for="display-name"
              >
                <b-form-input
                  id="display-name"
                  v-model="itemGroupData.display_name"
                  :state="getValidationState(validationContext)"
                />
              </b-form-group>
            </validation-provider>

            <!-- Item Detail -->
            <validation-provider
              #default="validationContext"
              name="Item Group Detail"
              rules="required|max:255"
            >
              <b-form-group
                label="Item Group Detail"
                label-for="item-group-detail"
              >
                <b-form-textarea
                  id="item-group-detail"
                  v-model="itemGroupData.details"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <template v-slot:description>
                  {{ itemGroupData.details.length }} / 255
                </template>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Item Price -->
            <validation-provider
              #default="validationContext"
              name="Item Group Price"
              rules="required"
            >
              <b-form-group
                label="Item Group Price"
                label-for="item-group-price"
              >

                <b-input-group
                  id="item-group-price"
                >
                  <b-input-group-text slot="prepend">
                    $
                  </b-input-group-text>
                  <cleave
                    v-model="itemGroupData.price"
                    class="form-control"
                    :class="getValidationStateNonBootstrap(validationContext)"
                    :options="priceOptions"
                  />
                </b-input-group>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Item Group Category -->
            <b-form-group label="Item Group Category">
              <category-picker
                v-model="itemGroupData.category_id"
              />
            </b-form-group>

            <!-- Item Image -->
            <validation-provider
              #default="validationContext"
              name="Item Group Image"
            >

              <b-form-group
                label="Item Group Image"
                label-for="image"
              >
                <b-form-file
                  id="image"
                  v-model="itemGroupData.item_image"
                  accept=".jpg,.jpeg,.png,.svg"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </template>

          <template v-else>
            <validation-provider
              #default="validationContext"
              name="item import"
              rules="required"
            >
              <b-form-group label="Item">
                <v-select
                  v-model="importData"
                  :options="importOptions"
                  :loading="loading"
                  label="name"
                  class="form-control p-0 border-control"
                  :class="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group label="Category">
              <category-picker
                v-model="itemGroupData.category_id"
              />
            </b-form-group>
          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <spinner-button
              :loading="saving"
              variant="primary"
              class="mr-2"
              type="submit"
            >{{ itemGroupData.item_group_id === 0 ? 'Add' : 'Update' }}</spinner-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupText,
  BFormFile,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, is, password, size, max, minValue,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CategoryPicker from '@/views/categories/CategoryPicker.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupText,
    BFormFile,
    SpinnerButton,
    Cleave,
    CategoryPicker,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewItemGroupSidebarActive',
    event: 'update:is-add-new-item-group-sidebar-active',
  },
  props: {
    isAddNewItemGroupSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      is,
      password,
      size,
      max,
      minValue,
      priceOptions: {
        numeral: true,
        numeralPositiveOnly: true,
      },
      localeOptions: [
        undefined,
        'en-US',
      ],
      formatOptions: {
        minimumFractionDigits: 2,
      },
    }
  },
  setup(props, { emit }) {
    const toast = useToast()

    const loading = ref(false)
    const saving = ref(false)

    const blankItemGroup = {
      item_group_id: 0,
      name: '',
      category_id: null,
      display_name: '',
      details: '',
      price: null,
      item_image: null,
    }

    const itemGroupData = ref(JSON.parse((JSON.stringify(blankItemGroup))))
    const importData = ref(null)
    const importOptions = ref([])
    const resetItemGroupData = () => {
      itemGroupData.value = JSON.parse(JSON.stringify(blankItemGroup))
      importData.value = null
    }

    const finish = () => {
      emit('refetch-data')
      emit('update:is-add-new-item-group-sidebar-active', false)
      saving.value = false
    }

    const onSubmit = () => {
      saving.value = true
      let action
      if (itemGroupData.value.item_group_id === 0) {
        action = store.dispatch('app-item/importItemGroup', {
          importData: importData.value,
          categoryId: itemGroupData.value.category_id,
        })
      } else {
        action = store.dispatch('app-item/updateItemGroup', { itemGroupId: itemGroupData.value.item_group_id, itemGroupData: itemGroupData.value })
      }
      action.then(() => {
        finish()
      }).catch(error => {
        if (error.response && error.response.data && error.response.data.error) {
          Object.keys(error.response.data.error.data).forEach(errorKey => {
            error.response.data.error.data[errorKey].forEach(e => {
              toast({
                component: ToastificationContent,
                props: {
                  title: e,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
          })
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error adding item group please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
        saving.value = false
      })
    }
    const fetchItems = () => {
      if (itemGroupData.value.item_group_id === 0) {
        loading.value = true
        store.dispatch('app-item/fetchImportItemGroups')
          .then(response => {
            importOptions.value = response.data
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong fetching import options please try again later',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            loading.value = false
          })
      }
    }
    const setItemGroup = itemGroup => {
      itemGroupData.value = {
        item_group_id: itemGroup.item_group_id,
        name: itemGroup.item_group_name,
        category_id: itemGroup.category_id,
        display_name: itemGroup.item_group_display_name,
        details: itemGroup.item_group_details,
        price: itemGroup.item_group_price,
        item_image: null,
      }
    }

    const {
      refFormObserver, getValidationState, getValidationStateNonBootstrap, resetForm,
    } = formValidation(resetItemGroupData)

    return {
      itemGroupData,
      onSubmit,
      loading,
      saving,
      importOptions,
      importData,

      refFormObserver,
      getValidationState,
      getValidationStateNonBootstrap,
      resetForm,
      fetchItems,
      setItemGroup,
    }
  },
}
</script>

<style lang="scss" scoped>
#add-new-item-group-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.text-overflow {
  text-overflow: ellipsis;
}

.border-control::v-deep .vs__dropdown-toggle {
  border: 0;
  border-radius: 0;
}
</style>
